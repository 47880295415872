.campaign-create-container .MuiInputBase-root{
    border: 1px solid #d9d9d9;
    height: 32px;
}

.campaign-create-container .MuiInputBase-root .MuiSelect-icon {
    color: #925fff;
}

.campaign-post-holder{
    position: relative;
    width: 100%;
    height: 200px;
    transition: all 0.3s;
    cursor: pointer;
    border: 0;
}

.campaign-post-holder:hover{
    opacity: 0.9;
}

.campaign-post-holder:hover .floatmessage{
    height: 100%;
}

.campaign-post-holder .floatmessage {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: rgba(30,30,71,0.65);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    transition: all 0.3s;
    overflow: hidden;
}

.selected-campaign-post .floatmessage{
    background-color: rgba(146,95,255,0.8);
}

.post-message-wrap{
    overflow-wrap: break-word;
    overflow-y: hidden;
    white-space: pre-wrap;
}

.post-campaign-list{
    padding: 8px 8px;
    background-color:  #f8f4ff;
    width: 100%;
    border-radius: 0px;
    cursor: pointer;
    transition: all 0.3s;
}

.post-campaign-list:hover{
    border-color: #925FFF;
    padding: 8px 12px;
}


.move-button{
    border: 0;
    background-color: #f0e9ff;
    color: #925FFF;
    border-radius: 0px;
    padding: 4px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}

.move-button svg{
    font-size: 18px;
    transition: all 0.2s;
}

.move-button:hover{
    opacity: 0.8;
}

.move-button:hover svg{
    color: #1e1e47;
}

.cal-holder .ant-picker-calendar-header{
    display: none;
}

.cal-holder .ant-picker-calendar-date-today{
    background: #925FFF !important;
}

.cal-holder .ant-picker-calendar-date-today::before{
    border-color: #925FFF !important;
}

.cal-holder th{
 font-weight: 500 !important;
}

.approval-filter-holder{
    background-color: #fff;
    border-radius: 0px;
    box-shadow: rgba(0,0,0,0.1), 0px 4px 20px -5px;
    width: 100%;
    padding: 16px;
}

.approval-filter-holder .ant-input-affix-wrapper {
    background: #f9f9f9 I !important;
}

.approval-filter-input{
    background-color: #f9f9f9 !important;
    color: #1e1e47;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    padding: 6px;
    border-radius: 0px;
    border: 0;
}

.approval-filter-input > *{
    background-color: #f9f9f9 !important;
}

.approval-sortby-button{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: auto;
    gap: 6px;
    color: #1e1e47;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s;
    height: 40px;
    border-radius: 0px;
}

.approval-sortby-button:hover{
    color: #925FFF;
}
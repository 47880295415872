.editpost-select {
    background-color: #fff;
    border-radius: 0px;
    height: 100%;
    width: 100%;
    font-weight: 500;
    color: #1E1E47;
    outline: 0;
    padding: 6px 10px;
    border: 1px solid #d9d9d9;
}

.editpost-select > * {
    border: 0 !important;
    outline: 0 !important;
    color: #1E1E47;
    font-size: 14px;
    font-weight: 500;
}

.editpost-select svg {
    color: #925fff;   
}

.edit-screen-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.edit-screen {
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding: 20px;
    background-color: #fff;

    display: flex;
    gap: 16px;
    flex-direction: column;
}

.edit-selected{
    border: 1px solid #925FFF;
}

.edit-section{
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
}

.edit-section:hover{
    opacity: 0.9;
    background-color: #ede4ff;
    border: 1px solid #925FFF;
    padding: 6px;
}

.edit-section:hover::after {
    content: '';
    position: absolute;
    top: -22px;
    right: -22px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    background-image: url('../../assets/edit_post_indicator.gif');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.edit-section:focus{
    background-color: #ede4ff;
}

.edit-tab{
    display: flex;
    flex-direction: column;
    gap: 6px;
    background-color: #fff;
    border-radius: 0px;
    padding: 20px;
    margin-top: 32px;
}

.gallery-edit-photo{
    transition: all 0.3s;
    object-fit: cover;
}

.gallery-edit-photo:hover{
    object-fit: contain;
}
.hover-chart-info{
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: none;
    max-height: 90px !important;
    height: 90px !important;
    min-width: 0;
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    flex: 1;
}

.hover-chart-info:hover{
    background-color: #f4efff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.small-icon-metric{
    background-color: #ede4ff !important;
    width: 30px !important;
    height: 30px !important;
}

.small-icon-metric svg{
    font-size: 16px !important;
    color: #925fff !important;
}

.analytics-filter-box{
    padding: 10px;
    border-radius: 0px;
    background-color: #ede4ff;
   /*  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
}

.analytics-filter-box .ant-input-affix-wrapper{
    background-color: #fff !important;
}

.MuiLinearProgress-bar {
    background-color: #925fff !important;
}

.MuiLinearProgress-root{
    background-color: #e0d2ff !important;
    height: 8px !important;
    border-radius: 0px !important;
}
/* CalendarStyles.css */
@media (max-width: 768px) {
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
      display: block;
      width: 100%;
      height: auto;
      text-align: center;
    }
  
    .ant-picker-calendar .ant-picker-calendar-header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .ant-picker-calendar .ant-picker-calendar-date {
      width: 14.28%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .ant-picker-calendar .ant-picker-calendar-mode-switch {
      margin-top: 10px;
    }
    
    .ant-picker-calendar .ant-select {
      width: 100% !important;
    }

    .ant-picker-calendar .ant-picker-content{
      table-layout: auto;
    }
  }
  

  .calendar-list-item {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px;
    background-color: #f7f7f7;
    border-radius: 0px;
    width: 100%;
    flex: 1;
  }
  
  .calendar-list-item svg{
    font-size: 16px;
  }
  
  .calendar-list-item > *{
    font-weight: 500;
    font-size: 12px;
    color: #1E1E47;
  }

  .events {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
  }
  .events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notes-month {
    font-size: 28px;
    text-align: center;
  }
  .notes-month section {
    font-size: 28px;
  }
.analytics-card{
    background-color: #fff;
    padding: 16px 22px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    border-radius: 0;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px 0px;
}

.analytics-card .card-avatar {
    width: 46px;
    height: 46px;
    padding: 6px;
}

.analytics-card svg {
    font-size: 20px;
}

.analytics-content{
    background-color: #fff;
    border-radius: 0;
    padding: 16px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.reactions-avatar{
    width: 40px;
    height: 40px;
    transition: all 0.3s;
}

.reactions-avatar:hover{
    transform: scale(1.05);
}

.reactions-avatar img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.attachment-comment{
    width: 64px;
    height: 64px;
    border-radius: 0;
    object-fit: cover;
    transition: all 0.3s;
}

.attachment-comment:hover{
    width: 200px;
    height: auto;
    object-fit: contain;
}

.analytics-content .ant-input-data-count{
 bottom: 6px;
 right: 6px;
}

.emoji-button-f{
    background: transparent;
    color: #1E1E47;
    border: 0;
    padding: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    border-radius: 0;
}

.emoji-button-f:hover{
    background: #f6f6f6;
  }


  .MuiDialog-paper{
    background-color: #f6f6f6 !important;
  }
*{
  font-family: 'Inter', sans-serif !important;                                     
  font-optical-sizing: auto !important;
  line-height: 155% !important;
  -webkit-font-smoothing: antialiased !important;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004) !important;
  -moz-osx-font-smoothing: grayscale !important;
  letter-spacing: 0.3px !important;
  }

  *::-webkit-scrollbar-button{
    display: none;
    width: 0;
    height: 0;
  }
   
  a {
    text-decoration: none !important;
  }

  .text-monster{
    font-family: 'Montserrat', sans-serif !important;
  }
  
  .my_profile-bg {
    width: 340px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  
  .my_profile_user-img {
    border-radius: 100%;
    margin-left: -64px;
  }
  
  .property_details-img {
    width: 100%;
  }
  


.css-1b3ondc-MuiPaper-root-MuiAppBar-root{
background-color: #FFFFFF !important;
box-shadow: none !important;
color: #1E1E47 !important;
}

main{
  background-color: #f7f7f7 !important;
  color: #1E1E47;
}

div[style*="bottom: 0px;"] {
  display: none !important;
}



.FB_Connect_C button:nth-child(1){
  display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    border-radius: 0px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-flex: unset;
    -ms-flex: unset;
    flex: unset;
    padding: 10px 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 130px;
    background-color: #fff;
    color: #1E1E47;
    font-size: 16px;
    font-weight: 500;
    gap: 10px;
    text-transform: capitalize;
}

.FB_Connect_C button:nth-child(1):hover{
  opacity: 0.9;
}

.okt_desc_default span{
  color: #925FFF;
}



:where(.css-dev-only-do-not-override-1jplveb).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today{
  border-color: #925FFF;
}

:where(.css-dev-only-do-not-override-1jplveb).ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value, :where(.css-dev-only-do-not-override-1jplveb).ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value, :where(.css-dev-only-do-not-override-1jplveb).ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value, :where(.css-dev-only-do-not-override-1jplveb).ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #925FFF;
}

:where(.css-dev-only-do-not-override-1jplveb).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  color: #925FFF;

}

:where(.css-dev-only-do-not-override-1jplveb).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #925FFF;
}

:where(.css-dev-only-do-not-override-1jplveb).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{
  color: #925FFF;
}

:where(.css-dev-only-do-not-override-1jplveb).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  color: #925FFF;
}

:where(.css-dev-only-do-not-override-1jplveb).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  border-color: #925FFF;
}

:where(.css-dev-only-do-not-override-1jplveb).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
  border-color: #925FFF;
}

:where(.css-dev-only-do-not-override-1jplveb).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #F8EDFF;
}

.ant-btn-primary{
  background-color: #925FFF;
}

.ant-btn-prmiary:hover{
  background-color: #925FFF !important;
  opacity: 0.8;
}


.notification-animation-box{
  animation: fadeIn 300ms ease-out backwards;
  background-color: rgba(255,255,255,1);
  backdrop-filter: blur(6px);
}

@keyframes fadeIn {
  from {
    transform: translateY(-250px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.selectedPostCampaign{
  border: 2px solid #925FFF !important;
}

.custom-select .ant-select-selector {
  height: 100% !important;
  color: #989898 !important;
  font-size: 14px;
  border: 1px solid #E6E6FA !important;
  border-radius: 0px !important;
  font-weight: 500 !important;
}

.custom-select .ant-select-selection-item, .custom-input input{
  font-weight: 500;
  font-size: 14px;
  height: 100% !important;
}

.custom-select .ant-select-selection-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.custom-select .ant-select-selector:hover{
  border: 1px solid #925FFF;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #E6E6FA !important;
}


.t_oktelo{
  font-size: 14px !important;
  color: #1E1E47 !important;
}

.t_important{
  font-size: 18px !important;
  color: #1E1E47 !important;
}

.t_link{
  color: #6a3bcf !important;
}

.t_red{
  color: #C41E3A !important;
}

.t_misc{
  font-weight: 300 !important;
}

.t_desc{
  font-weight: 400 !important;
  line-height: 22px !important;
} 

.t_highlight{
  font-weight: 500 !important;
}

.miscgray{
  color: #6C6C89 !important;
}

.t_purple{
  color: #925FFF !important;
}

.t_main_title{
  color: #1E1E47 !important;
  font-size: 26px !important;
  font-weight: 700 !important;
}

.t_secondary_title{
  color: #1E1E47 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  transition: all 0.2s;
}

.t_white{
  color: #fff !important;
}

.t_oktelo_title {
  color: #1E1E47 !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.t_oktelo_text {
  color: #1E1E47 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.t_oktelo_prop {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.tag_oktelo {
  border-radius: 0px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.tag_oktelo span {
  line-height: 0px !important;
}

.b_wp{
  border-radius: 0px;
  padding: 16px;
  opacity: 0.9;
  min-height: 200px;
  color: #1E1E47;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) -4px 9px 25px -6px !important;
  transition: all 0.4s;
  border: 1px solid white;
}

.b_wp_nohover{
  border-radius: 0px;
  padding: 16px;
  opacity: 1;
  min-height: 50px;
  color: #1E1E47;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) -4px 9px 25px -6px !important;
  transition: all 0.4s;
  border: 1px solid white;
}

.b_okt_commentsbox{
  padding: 14px;
  min-height: auto;
  color: #1E1E47;
  background: #fff; 
  transition: all 0.4s;
  border-radius: 0px;
  border: 1px solid #f7f7f7;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.b_okt_commentsbox:hover{
  opacity: 0.9 !important;
  padding: 16px;
}

.b_wp:hover{
  opacity: 0.85 !important;
  border: 1px solid #eeeeee;
  padding: 18px;
}

.b_wp:hover .t_secondary_title, .b_wp:hover svg{
  color: #925fff !important;
}


.b_wp_ap{
  
  background-color: #fff;
  background-image: url('./assets/rocket_ap_5.png');
  background-position-x: 100%;
  background-position-y: 50%;
  background-size: 50%;
  background-repeat: no-repeat;

}

.b_wp_welcome{
  background-color: #fff;
  background-image: url('./assets/oktelo_ai_robot2.png');
  background-position-x: 130%;
  background-position-y: 50%;
  background-size: 50%;
  background-repeat: no-repeat;
}

.b_wp_select{
  background-color: #fff;
  background-image: url('./assets/ai_lovecount.png');
  background-position-x: 110%;
  background-position-y: 50%;
  background-size: 40%;
  background-repeat: no-repeat;
}

.b_wp_card{
  background-color: #fff;
  background-image: url('./assets//home_card_1.jpg');
  background-size: cover;
}

.b_wp_card_b{
  background-color: #fff;
  background-image: url('./assets//home_card_2.jpg');
  background-size: cover;
}

.t_highlight_yellow{
  color: rgba(255,237,130,1) !important;
}

.ai_form label, .ai_form .ant-form-item-extra{
  color: #1E1E47 !important;
}

.ai_form label{
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ai_form .ant-form-item-extra{
  font-size: 12px;
  font-weight: 300;
}

.ai_form .ant-switch-checked{
  background: #925fff;
}

.ai_form .ant-checkbox + span{
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ant-form-item-extra{
  margin-top: 4px;
  font-weight: 300;
}

/* .ai_page_selector .ant-select-selector, .ai_form .ant-select-selector, .ant-input-affix-wrapper{
  background: rgba(255,255,255, 0.5) !important;
  color: #1E1E47 !important;
}
 */

.ai_form input:focus, input:hover, .ant-select-selector:focus, .ant-select-selector:hover{
  border-color: #fff !important;
}

.t_highlight_gray{
  color: #989898 !important;
}

.oktelo_calendar_item{
  transition: all 0.3s;
}

.oktelo_calendar_item span:hover{
  color: #925fff !important;
}

.oktelo_home_chart{
  transition: all 0.4s;
  background-color: #fff;
  border-radius: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
  padding-bottom: 22px;
  position: relative;
  flex: 1;
  max-height: 144px;
}

.oktelo_home_chart:hover{
  opacity: 0.9;
  box-shadow: rgba(100, 100, 111, 0.05) 0px 7px 29px 0px;
}

.okt_story_img{
  max-width: 15.2%;
  max-height: auto;
  object-fit: cover;
  border-radius: 0px;
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
  transition: all 0.1s linear;
  cursor: pointer;
}

.okt_story_img:hover{
  border: 2px solid #925fff;
  border-radius: 0px;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
}



.okt_home_form .ant-select-selector{
  background: #fff !important;
  border: 0 !important;
  border-radius: 0px !important;
}


.b_oktelo{
  border-radius: 0px;
  border: none;
  text-transform: none !important;
  background: transparent !important;
}

.b_oktelo:hover, .b_oktelo_purple:hover{
  opacity: 0.9;
  background: #f6f6f6 !important;
}

.b_oktelo_purple{
  border-radius: 0px;
  border: none;
  text-transform: none !important;
  background: transparent !important;
  color: #925FFF !important;
}

.b_oktelo_red{
  border-radius: 0px;
  border: none;
  font-weight: 400 !important;
  text-transform: none !important;
  background: transparent !important;
  color: #C41E3A !important;
  transition: all 0.4s;
}

.b_oktelo_warning{
  border-radius: 0px;
  border: none;
  font-weight: 400 !important;
  text-transform: none !important;
  background: transparent !important;
  color: #f09022 !important;
}

.b_oktelo_action{
  border-radius: 0px !important;
  border: none !important;
  background-color: #925fff !important;
  text-transform: none !important;
  color: #fff !important;
  font-weight: 400 !important;
  transition: all 0.3s;
}

.b_oktelo_action_delete{
  border-radius: 0px !important;
  border: none !important;
  background-color: #C41E3A !important;
  text-transform: none !important;
  color: #fff !important;
  transition: all 0.3s;
}

.b_oktelo_action:hover, .b_oktelo_action_delete:hover{
  opacity: 0.9 !important;
}

.b_oktelo_disabled{
  border-radius: 0px;
  border: none;
  font-weight: 400 !important;
  text-transform: none !important;
  background: transparent !important;
  color: #989898 !important;
}



.s_ai_select p{
  font-size: 14px;
  color: #1E1E47;
  font-weight: 400;
}

.okt_modal_button:hover{
  background-color: #925FFF !important;
  opacity: 0.8;
}

.okt_modal_button{
  width: 30%;
}

.oktelo_map_container{
  transform: scale(1.2);
  transition: all 0.5s;
}

.oktelo_map_container:hover{
  transform: scale(2);
}

.oktelo_popover{
  max-width: 300px;
}

.oktelo_create_form .oktelo_form_element, .oktelo_create_form input, .oktelo_create_form ul{
  background-color: #fff !important;
  color: #1E1E47 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  margin-top: 6px;
  border-radius: 0px !important;
}

.oktelo_create_form p{
  margin-left: 0;
}

.oktelo_card{
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 24px;
  padding-top: 24px;
  gap: 10px;
  background-color: #fff ;
  border-radius: 0px;
  min-height: 220px;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  flex: 1;
  border: 1px solid transparent;
  transition: border 0.4s;
}

.oktelo_card:hover{
  border: 1px solid #d9d9d9;
}

.oktelo_card_hover{
  cursor: pointer;
  transition: all 0.2s ease-out;
  text-wrap: pretty;
}

.oktelo_card_hover:hover{
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 22px;
  opacity: 0.95;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 8px !important;
}

.oktelo_card_tag{
  border-radius: 0px;
  padding: 2px 10px;
  position: absolute;
  left: 12px;
  top: 12px;
  transition: all 0.2s ease-out;
}

.oktelo_card_hover:hover .oktelo_card_tag{
  left: 14px;
  top: 14px;
  transform: scale(1.1);
}

.oktelo_card_button{
  color: #925FFF !important;
  background: transparent !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  transition: all 0.3s ease-out !important;
  border: 0 !important;
  box-shadow: none !important;
}

.oktelo_card_button:hover{
  color: rgba(146, 95, 255, 0.6);
  transform: scale(1.1);
}

.login_primary_button{
  color: #fff !important;
  width: 100% !important;
  background-color: #925FFF !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  transition: all 0.3s ease-out !important;
  text-transform: none !important;
  padding: 16px !important;
}

.login_secondary_button{
  color: #1E1E47 !important;
  width: 100% !important;
  background-color: #f0f0f0 !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  transition: all 0.3s ease-out !important;
  text-transform: none !important;
  padding: 10px !important;
}

.login_primary_button:hover, .login_secondary_button:hover, .oktelo_connect_grhf button:first-child:hover{
  opacity: 0.9;
  transform: scale(1.02);
}

.login_box{
  background-image: url('./assets/bg_test_4.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.register_box{
  background-image: url('./assets/bg_register.jpg');
  background-size: cover;
  background-repeat: no-repeat; 
}

.login_card{
  backdrop-filter: blur(15px);
  background-color: rgba(255,255,255,0.8) !important;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.oktelo_connect_grhf button:first-child {
  color: #1e90ff !important;
  background: transparent !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  transition: all 0.3s ease-out !important;
  border: 0 !important;
  box-shadow: none !important;
  cursor: pointer;
}

.expired-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent overlay */
  z-index: 9999; /* Ensure it appears above other content */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}

/* Additional styles to prevent interaction with underlying content */
.expired-overlay {
  pointer-events: auto;
}

.oktelo_icon_button{
  display:block !important;
  width:40px !important;
  height:50px !important;
  line-height:40px !important;
/*   border: 1px solid #925fff !important; */
  border-radius: 50% !important;
  color:#925fff !important;
  text-align:center !important;
  text-decoration:none !important;
  background: transparent;
  font-size:10px !important;
  transition: all 0.6s !important;
}


.oktelo_icon_button:hover{
  background: transparent !important;
  color: #c9b3f8 !important;
  transform: scale(1.05);
}

.b_oktelo_red:hover{
  background: transparent !important;
  color: #ec714b !important;
}

.oktelo-carousel {
  width: 100%;
  height: 500px;
}

.oktelo-carousel img {
  width: 100%;
  height: 500px;
}


.preview_input_box input, .preview_input_box label {
  color: #1E1E47 !important
  
}

.preview_input_box input {
  border: 1px solid #dedede;
  outline: 1px solid #dedede;
  border-radius: 0px;
}

.preview_input_box{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.oktelo_sider::-webkit-scrollbar 
{
  width: 5px;
}

/* Track */
.oktelo_sider::-webkit-scrollbar-track {
  background: #1e1e47;
}

/* Handle */
.oktelo_sider::-webkit-scrollbar-thumb {
  background: #61617e;
}

/* Handle on hover */
.oktelo_sider::-webkit-scrollbar-thumb:hover {
  background: #61617e;
}


.instagram_border {
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  border-radius: 50%;
  border: 2px solid transparent;
}

.upcoming-hover {
  transition: all(0.3s);
  cursor: pointer;
}

.upcoming-hover:hover{
  opacity: 0.9;
}

.t_oktelo_light {
  font-weight: 300 !important
}

.t_oktelo_normal {
  font-weight: 400 !important;
}

.t_oktelo_medium {
  font-weight: 500 !important;
}

.t_oktelo_semibold {
  font-weight: 600 !important;
}

.t_oktelo_bold {
  font-weight: 700 !important;
}

.darkblue {
  color: #1E1E47 !important;
}

.size_16 {
  font-size: 16px !important;
}

.size_24 {
  font-size: 24px !important;
}

.size_32 {
  font-size: 32px !important;
}

.size_14 {
  font-size: 14px !important;
}

.size_12 {
  font-size: 12px !important;
}

.size_10 {
  font-size: 10px !important;
}

.size_18 {
  font-size: 18px !important;
}

.size_20 {
  font-size: 20px !important;
}

.oktpurple {
  color: #925FFF !important;
}

.notransform {
  text-transform: none !important;
}

.oktgray {
  color: #d3d3d3 !important;
}

.oktelo_input_filter{
  color: #1E1E47 !important; 
  width: 200px;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  background-color: #fff !important;
  border-radius: 0px;
  border: 0px;
}

.oktelo_input_filter .ant-select-selector{
  background: transparent !important;
  color: #1E1E47 !important;
}

.oktelo_input_filter .ant-select-selection-item{
  font-size: 12px;
  font-weight: 500;
  color: #1E1E47 !important;
}

.oktelo_input_transparent{
  border-radius: 0px;
  font-size: 12px;
  background-color: transparent;
}

.oktelo_input_transparent .ant-select-selector{
  background: transparent !important;
  color: #1E1E47 !important;
  font-weight: 400;
}

.form_item_stepbystep {
  background-color: #fff;
  padding: 12px;
  padding-top: 20px;
  border-radius: 0px;
  margin: 14px 0px;
}

.oktelo_approve_table{
  background-color: #fff !important;
  width: 100% !important;
  border: 0;
  box-shadow: none !important;
}

.oktelo_approve_table .MuiTableRow-head th, .oktelo_approve_table .MuiTableCell-root{
  color: #1E1E47 !important;
  border-bottom: 0;
}

/* .oktelo_approve_table tr:hover{
  background-color: #f8f8f8 !important;
} */

.oktelo_approve_table thead tr:hover:first-child{
  background-color: #fff !important;
}

.oktelo_approve_table td{
  padding-left: 5px;
  padding-right: 4px;
  white-space: nowrap;
}

.b_oktelo_purple_action {
  background-color: #925FFF;
  color: #fff;
  text-transform: none;
  border-radius: 0px;
  border: 0;
  padding: 5px 15px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s;
}

.b_oktelo_purple_action:hover, .b_oktelo_red_action:hover{
  opacity: 0.8;
}

.b_oktelo_red_action {
  background-color: #f9f9f9;
  color: #1E1E47;
  text-transform: none;
  border-radius: 0px;
  padding: 5px 15px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  transition: all 0.3s;
}

.settings_layout .ant-menu-item-selected{
  color: #925FFF !important;
}

.settings_layout .ant-menu-item-selected::after, .settings_layout .ant-menu-item::after{
  border-bottom: 0 !important;
}

.settings_layout .ant-menu-submenu-active::after{
  border-bottom: 0 !important;
}

.login_provider_stack button {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: 0;
  padding: 6px 12px;
  text-transform: none;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 0px;
  cursor: pointer;
  transition: all 0.3s;
}

.login_provider_stack button:hover {
  transform: scale(1.05);
}

.login_provider_stack button:first-of-type{
  background-color: #f0f0f0;
  color: #1E1E47;
}

.login_provider_stack .linkedin-connect {
  background-color: #0077B5;
}

.login_provider_stack .google-connect {
  background-color: #E37400;
}

.login_provider_stack .copy-affiliate {
  background-color: #925FFF;
}

.login_provider_stack .claim-reward {
  background-color: #c93098;
}

.css-1iwgetb-MuiPaper-root-MuiDrawer-paper{
  background-color: #1e1e47 !important;
}

.oktelo_card {
  background-color: #fff;
  border-radius: 0px;
  padding: 16px;
}

.oktelo-create-post-form{
  display: flex;
  gap: 8px;
  flex-direction: column;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 12px;
}

.oktelo-create-post-form .ant-input-data-count{
  font-size: 10px;
  bottom: 0px;
  right: 6px;
}

.oktelo-create-post-form .MuiSwitch-track{
  background-color: #d3d3d3;
}

.oktelo-create-post-form .ai-content-button{
  background-color: #faf4fd;
  color: #8234ff;
  border: 0;
  padding: 6px 12px;
  border-radius: 0px;
  font-size: 13px;
  line-height: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s;
}

.oktelo-create-post-form .ai-content-button:hover{
  background-color: #bc74ff;
  color: #fff;
}

.oktelo-create-post-form .emoji-select-button{
  background: transparent;
  color: #1E1E47;
  border: 0;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  border-radius: 0px;
}

.oktelo-create-post-form .emoji-select-button:hover{
  background: #f6f6f6;
}

.preview-text-create{
  overflow-wrap: break-word;
  overflow-y: hidden;
  white-space: pre-wrap;
  font-weight: 300;
  margin-bottom: 12px;
  color: #1e1e47;
}

.social-select-button{
  background-color: #f6f6f6;
  padding: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0px;
  border: 0;
}

.social-select-button:hover{
  background-color: #f5f4fd !important;
  color: #925FFF !important;
}

.submit-post-creator-row .submit{
  background-color: #925FFF;
  color: #fff;
  padding: 6px 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0px;
  border: 0; 
}

.submit-post-creator-row .cancel{
  background-color: #f6f6f6;
  color: #1E1E47;
  padding: 6px 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0px;
  border: 0; 
}

.submit-post-creator-row .draft{
  background-color: #faf4fd;
  color: #8234ff;
  border: 0;
  padding: 6px 12px;
  border-radius: 0px;
  font-size: 13px;
  line-height: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s;
}

.submit-post-creator-row button{
  width: 100%;
  justify-content: center;
}

.submit-post-creator-row button:hover{
  opacity: 0.9;
}
.oktelo-create-post-form {
  border: 0;
}
.oktelo-create-post-form .selector-page{
  border: 1px solid #dfdfdf !important;
  min-height: 32px;
  padding-left: 8px;
  background-color: #fff;
  border: 0;
}

.oktelo-create-post-form .selector-page * {
  border: 0;
  outline: 0;
  border-radius: 0;
}

.oktelo-create-post-form * {
  border-radius: 0;
}

.selector-page:focus, .MuiSelect-select:focus{
  border: 0;
  outline: 0;
}

.oktelo-create-post-form .MuiSelect-select{
  padding-right: 0 !important;
}

.oktelo-create-post-form + .MuiSelect-select {
  outline: 0;
  border: 0;
}

.css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  background-color: #fff !important;
}

.create-menuitem + .Mui-selected{
  background-color: #faf4fd !important;
}

.css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 0 !important;
}

.selector-page .MuiSelect-icon{
  color: #925FFF;
}

.cretor-content-layout::-webkit-scrollbar {
  width: 5px;
}

.cretor-content-layout::-webkit-scrollbar-track {
  background: transparent;
}

.cretor-content-layout::-webkit-scrollbar-thumb {
  background: #d8caff;
}

.cretor-content-layout::-webkit-scrollbar-thumb:hover {
  background: #925fff;
}

.preview-sider::-webkit-scrollbar {
  width: 0px;
}

.remove-time {
  width: auto;
  color: #925FFF;
  font-size: 12px;
  font-weight: 500;
  background-color: transparent;
  border: 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0;
}

.remove-time:hover{
  opacity: 0.9;
}

.preview-carousel-container div{
  width: 100%;
  height: 100%;
}

.image-url-button{
  background-color: #faf4fd;
  color: #8234ff;
  border: 0;
  padding: 6px 12px;
  border-radius: 0px;
  font-size: 13px;
  line-height: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s;
  width: auto;
}

.image-url-button svg{
  font-size: 18px;
}

.image-url-button:hover {
  background-color: #bc74ff;
  color: #fff;
}

.posts-select {
  background-color: #fff;
  border-radius: 0px;
  height: 100%;
  width: 100%;
  font-weight: 500;
  color: #1E1E47;
  outline: 0;
  padding: 8px 4px;
}

.posts-select > * {
  border: 0 !important;
  outline: 0 !important;
  color: #1E1E47;
  font-size: 14px;
  font-weight: 500;
}

.posts-select svg {
  color: #925fff;
}

.posts-select-item {
  color: #1E1E47 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.posts-select-item svg {
  color: #925FFF;
}

.posts-select-item:hover{
  background-color: #e3d6ff !important;
}

.posts-select-item + .Mui-selected {
  background-color: #e3d6ff !important;
}

.css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  box-shadow: none !important;
}

.new_post_button{
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  background-color: #925FFF;
  color: #fff;
  padding: 7px 20px;
  border: 0;
  font-size: 18px;
  cursor: pointer;
  border-radius: 0px;
  transition: all 0.3s;
}

.new_post_button span {
  font-size: 14px;
  font-weight: 500;
}

.filter-post-view-button{
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  background-color: transparent;
  padding: 7px 10px;
  border: 0;
  font-size: 18px;
  border-radius: 0px;
  transition: all 0.3s;
  cursor: pointer;
}

.filter-post-view-button:hover p, .filter-post-view-button:hover svg{
  color: #925FFF !important;
}

.post-grid-card{
  background-color: #fff;
  border-radius: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  height: 100%;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s;
}

.post-grid-card:hover{
  background-color: #f9f9f9;
}
.post-grid-card img {
  border-radius: 0px 0px 0px 0px;
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: all 0.4s;
}



.post-grid-type-box {
  position: absolute;
  top: 6px;
  left: 6px;
  width: auto;
  gap: 4px;
  border-radius: 0px;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: all 0.2s;
  z-index: 99;
}

.post-grid-type-box svg {
  font-size: 18px;
  color: #925FFF;
}

.post-list-icon-cell{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
  transition: all 0.2s;
  width: auto;
}

.post-list-icon-cell svg {
  font-size: 18px;
  color: #925FFF;
}

.post-list-icon-cell .parent-name{
  font-size: 0px;
  transition: all 0.2s;
}

.post-list-icon-cell:hover .parent-name{
  display: block;
  font-size: 12px;
}

.post-grid-type-box .parent-name{
  font-size: 0px;
  transition: all 0.2s;
}

.post-grid-type-box:hover .parent-name{
  display: block;
  font-size: 12px;
}

.post-grid-card .post-message-box{
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(30,30,71,0.65);
  width: 100%;
  backdrop-filter: blur(6px);
  transition: all 0.3s;
  border-radius: 0px 0px 0px 0px;
}

.post-grid-card:hover .post-message-box{
  opacity: 0;
}

.post-grid-card:hover img{
  transform: scale(0.95);
  object-fit: contain;
}

.post-grid-approval-box {
  position: absolute;
  top: 6px;
  right: 6px;
  width: auto;
  border-radius: 0px;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 4px;
}

.post-grid-approval-box svg {
  font-size: 18px;
}

.post-grid-actionbox {
  width: auto;
  border-radius: 0px;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  padding: 4px;
}

.post-grid-pagination {
  padding: 3px 20px;
  border: 0;
  background-color: #bc74ff;
  color: #fff;
  font-size: 16px;
  border-radius: 0px;
  cursor: pointer;
  transition: all 0.3s;
}

.post-grid-pagination:disabled {
  background-color: #e0e0e0; /* Lighter color for disabled state */
  color: #9e9e9e; /* Text color for disabled state */
  cursor: not-allowed;
}

.post-list-card{
  background-color: #fff;
  border-radius: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  position: relative;
  padding-right: 14px;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 0.3s;
}

.post-list-card:hover{
  background-color: #f9f9f9;
}

.post-list-card .ant-tag{
  cursor: pointer;
}

.post-list-card img {
  border-radius: 0px 0px 0px 0px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.okt-calendar-bgroup button:hover{
  background-color: #925FFF;
  color: #fff;
  outline: 0;
  border-color: #925FFF;
}

.post-filter-row-main input{
  background-color: #fff;
  color: #1E1E47;
  border-radius: 0px;
  border: 0;
}

.post-filter-row-main {
  height: 40px;
}

.posts-select-input {
  background-color: #fff;
  border-radius: 0px;
  height: 100%;
  width: 100%;
  font-weight: 500;
  color: #1E1E47;
  outline: 0;
  padding: 8px 4px;
}

.posts-select-input > * {
  border: 0 !important;
  outline: 0 !important;
  color: #1E1E47;
  font-size: 14px;
  font-weight: 500;
}

.posts-select-input svg {
  color: #925fff;
}


.postview-action{
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0px;
  padding: 6px;
  color: #fff;
  width: 85%;
  cursor: pointer;
  transition: all 0.3s;
}

.postview-action:hover{
  opacity: 0.9;
}

.postview-action svg{
  font-size: 18px;
}

.purple-action{
  background-color: #925FFF;
}

.darkblue-action{
  background-color: #1E1E47;
}



.home-action-button{
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0px;
  padding: 6px 16px;
  color: #fff;
  width: auto;
  cursor: pointer;
  transition: all 0.3s;
}

.home-action-button svg{
  font-size: 16px;
}

.home-action-button:hover{
  opacity: 0.85;
  padding: 6px 18px;
}

.b925 {
  background-color: #925FFF;
  color: #fff;
}

.bf7b {
  background-color: #ff5f7b;
  color: #fff;
}

.bc6a{
  background-color: #ede4ff;
  color: #6c28ff; 
}

.b8f5{
  background-color: #e0e8f5;
  color: #486490;
}

.b25f{
  background-color: #ff925f;
  color: #fff; 
}

.b1e9{
  background-color: #1e90ff;
  color: #fff;
}

.be44{
  background-color: #E4405F;
  color: #fff;
}


.bc6a-gradient {
  background: rgb(214,194,255);
background: linear-gradient(345deg, rgba(214,194,255,1) 0%, rgba(237,228,255,1) 100%);
}

.bccf{
  background-color:  #35357D;
  color: #fff;
}

.b925-gradient {
  background: rgb(159,114,255);
  background: linear-gradient(315deg, rgba(159,114,255,1) 0%, rgba(146,95,255,1) 50%, rgba(159,114,255,1) 100%);
}

.b9d9{
  background-color: #f5f5f5;
  color: #1E1E47; 
}

.b9df{
  background-color: #ffe9df;
  color: #ff5f7b; 
}

.home-action-button:disabled{
  opacity: 0.9;
  cursor: no-drop;
}



.chart-box{
  border-radius: 0px;
  background-color: #fff;
  padding: 18px;
  width: 100%;
}

.chart-box-purple {
  background-color: #925fff;
  color: #fff;
}

.audience-smallcard {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 6px 12px;
  border-radius: 0px;
  width: 100%;
}



.campaign-avatar-post{
  transition: all 0.3s;
  cursor: pointer;
}

.campaign-avatar-post:hover{
  margin-right: 5px;
  margin-left: 10px;
  opacity: 0.8;
}

.post-list-table td{
  border: 1px solid #efefef;
}

.post-list-table tr{
  transition: all 0.3s;
}

.post-list-table tbody > tr:hover{
  background-color: #f5f1fd;
}

.post-list-table{
  border-radius: 0px !important;
}

.post-list-table thead{
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.post-list-table > thead > tr > th{
  border-bottom: 0;
  margin-bottom: 18px;
}

.post-list-table > thead > tr > th:first-child{
  border-radius: 0px 0 0 0px;
}

.post-list-table > thead > tr > th:last-child{
  border-radius: 0 0px 0px 0;
}


.edit-screen-container .ant-input-data-count{
  bottom: 6px;
  right: 6px;
}

.post_home{
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.post_home_container {
  scrollbar-width: thin;          /* Firefox */
  scrollbar-color: #35357D #fff;  /* Firefox */
}

/* For WebKit browsers */
.post_home_container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.post_home_container::-webkit-scrollbar-thumb {
  background: #35357D;
  border-radius: 0px;
}

.post_home_container::-webkit-scrollbar-thumb:hover {
  background: #11112b;
}

.post_home_container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.post_home_container::-webkit-scrollbar-button{
  display: none;
  height: 0;
  width: 0;
}

.integration-holder{
  background-color: #fff;
  border-radius: 0px;
  padding: 16px 20px;
  border: 1px solid #f6f6f6;
  width: 100%;
}

.integration-settings-button{
  border-radius: 0px;
  background-color: #f6f6f6;
  color: #925fff;
  padding: 6px;
  border: 0;
  font-size: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.integration-settings-button svg {
  font-size: 16px;
}

.integration-settings-button:hover {
  background-color: #ede4ff;
  color: #6c28ff;
}

.ant-tour{
  width: 90%;
  max-width: 650px;
}

.ant-tour button {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.ant-tour .ant-tour-buttons{
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.aiInteractionBox{
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  padding: 26px;
  border-radius: 0px;
}


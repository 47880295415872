.canva-item-box{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 0px;
    position: relative;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.canva-item-box-image{
    width: 100%;
    height: 200px;
    max-height: 300px;
    border-radius: 0px;
}

.canva-item-box img{
    border-radius: 0px; 
}

.canva-item-content{
    padding: 12px;
}

.canva-item-box-title{
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(255,255,255,0.5);
    border-radius: 0px;
    padding: 4px 8px;
    width: inherit;
}

.canva-item-buttons{
/*     position: absolute;
    bottom: 6px;
    left: 6px; */
    width: 100%;
}

.canva-item-buttons button{
    box-shadow: rgba(0,0,0,0.2) 0px 0px 10px -2px;
    width: 100%;
}

/* .cinema-modal{
    backdrop-filter: blur(4px);
    background-color: rgba(0,0,0,0.6);
} */

.localmedia-holder{
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-direction: column;
    position: relative;
    border-radius: 0px;
}

.localmedia-holder img {
    width: 100%;
    height: 200px;
    max-height: 300px;
    border-radius: 0px;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .okt_mobile_invisible {
      display: none !important;
    }
    .t_main_title {
      font-size: 18px !important;
    }
    .t_secondary_title {
      font-size: 16px !important;
    }
    .size_24 {
      font-size: 18px !important;
    }
    .size_32 {
      font-size: 22px !important;
    }
  }

  @media screen and (max-width: 900px) {
    .my_profile-bg {
        width: 100%;
        border-radius: 15px;
    }
  
    .my_profile_user-img {
        margin-left: 0px;
        margin-top: -64px;
    }
  
    .property_details-img {
        width: 100%;
        height: auto;
    }
  }


  @media only screen and (max-width: 600px)  {
    .css-1p51e29-MuiPaper-root-MuiCard-root{
      width: 100% !important;
      
    }
    .css-k97ujd{
      justify-content: center;
    }
    
  }

  @media only screen and (max-width: 800px) {
    .post-grid-card img {
      height: 250px;
    }
  }
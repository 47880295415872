/* AuthLayout.css */
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5; /* Or any other background color */
    overflow-y: auto;
}

.auth-content {
    width: 100%;
    height: 100%;
    max-width: 100%; /* Or any width that fits your design */
}

.auth-container-holder {
    background-color: #f7f8fc;
  }
  
  .auth-container-background {
    background-color: #fff;
    border-radius: 0px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 1177px;
    margin: auto;
    overflow: hidden;
    height: auto;

  }
  
  .login-box-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .login-box-right {
    background: rgb(146,95,255);
    background: radial-gradient(circle, rgba(146,95,255,1) 0%, rgba(196,169,255,1) 100%);
    display: none;
  }
  
  @media (min-width: 960px) {
    .login-box-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .login_primary_button {
    background-color: #925fff;
    color: #fff;
    border: none;
    width: 100%;
    border-radius: 0px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .login_secondary_button {
    background-color: transparent;
    color: #925fff;
    border: none;
    width: 100%;
    border-radius: 0px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .login-box-left input{
    background-color: #f9f9f9 !important;
  }

.approval-grid-box{
    background-color: #fff;
    width: 100%;
    border-radius: 0;
    box-shadow: rgba(0,0,0,0.1), 0px 4px 20px -5px;
    padding: 36px 24px;
    height: 100%;
}

.manage-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 0;
    transition: all 0.3s;
    border: 0;
    padding: 2px;
    cursor: pointer;
}

.manage-button svg{
    font-size: 18px;
}

.manage-button:hover{
    opacity: 0.9;
}

.manage-purple{
    background-color: #ede4ff;
}

.manage-purple svg{
    color: #925FFF;
}

.manage-red{
    background-color: #ffe7e7;
}

.manage-red svg{
    color: #BB271A;
}

.approval-item-box{
    background-color: #f9f9f9;
    border-radius: 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    cursor: pointer;
    width: 100%;
}

.approval-item-box svg{
    font-size: 18px;
}

.approval-item-box img{
    width: 38px;
    height: 38px;
    border-radius: 0;
    object-fit: cover;
}

.approval-item-box .approval-tag{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    height: 22px;
    font-size: 12px;
    font-weight: 500;
    padding: 6px;
}

.success-tag{
    background-color: #eaffe0;
    color: #89e85d !important;
    border: 1px solid #89e85d;
}

.reject-tag{
    background-color: #ffebf0;
    color: #e85d7e !important;
    border: 1px solid #e85D7e;
}

.comment-tag{
    background-color: #e7edff;
    color: #5d84e8 !important;
    border: 1px solid #5d84e8;
}

.approval-filter-holder{
    background-color: #fff;
    border-radius: 0;
    box-shadow: rgba(0,0,0,0.1), 0px 4px 20px -5px;
    width: 100%;
    padding: 16px;
}

.approval-filter-holder .ant-input-affix-wrapper {
    background: #f9f9f9 I !important;
}

.approval-filter-input{
    background-color: #f9f9f9 !important;
    color: #1e1e47;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    padding: 6px;
    border-radius: 0;
    border: 0;
}

.approval-filter-input > *{
    background-color: #f9f9f9 !important;
}

.approval-sortby-button{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: auto;
    gap: 6px;
    color: #1e1e47;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s;
    height: 40px;
    border-radius: 0;
}

.approval-sortby-button:hover{
    color: #925FFF;
}


.approval-item-comment{
    margin-left: 12px;
    margin-top: 10px;
    width: 90%;
    background-color: #f1f1f1;
    padding: 8px;
    border-radius: 0;
}

.approval-btn-hover:hover{
    opacity: 0.9;
}

.approve-button{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    padding: 6px 14px;
    border-radius: 0;
    background-color: #77ab59;
    font-size: 14px;
    font-weight: bold;
    border: 0;
    cursor: pointer;
  }
  
  .approve-button svg {
    font-size: 18px;
    color: #fff;
  }
  
  .comment-button{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    padding: 6px 14px;
    border-radius: 0;
    background-color: #5977ab;
    font-size: 14px;
    font-weight: bold;
    border: 0;
    cursor: pointer;
  }
  
  .comment-button svg {
    font-size: 18px;
    color: #fff;
  }
  
  .reject-button{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    padding: 6px 14px;
    border-radius: 0;
    background-color: #ab5977;
    font-size: 14px;
    font-weight: bold;
    border: 0;
    cursor: pointer;
  }
  
  .reject-button svg {
    font-size: 18px;
    color: #fff;
  }
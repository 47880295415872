.social-post-box{
    background-color: #fff;
    border-radius: 0;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    height: 100%;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s;
}

.social-post-box .reaction-box{
    display: flex;
    align-items: flex-end;
    padding: 12px;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: rgba(146,95,255, 0.8);
    backdrop-filter: blur(2px);
    transition: all 0.3s;
    border-radius: 0;
}

.social-post-box .reaction-box p {
    font-size: 14px;
    transition: all 0.3s;
}

.social-post-box:hover .reaction-box{
    height: 100%;
    align-items: center;
    backdrop-filter: blur(5px);
    background: rgba(146,95,255, 0.9);
    border-radius: 0;
}

.social-post-box:hover .reaction-box p{
    font-size: 16px;
}

.social-post-box:hover .reaction-box .reaction-stack{
    flex-direction: column;
    font-size: 18px;
    gap: 16px;
}

.social-post-box:hover .trophy-box, .social-post-box:hover  .date-box{
    background-color: #fff;
}

.social-post-box .reaction-box svg{
    color: #fff;
}

.social-post-box .trophy-box{
    position: absolute;
    top: 6px;
    left: 6px;
    width: auto;
    background-color: rgba(255,255,255,0.8);
    padding: 4px 10px;
    border-radius: 0;
}

.social-post-box .trophy-box svg{
    font-size: 14px;
}

.social-post-box .date-box{
    position: absolute;
    top: 6px;
    right: 6px;
    width: auto;
    background-color: rgba(255,255,255,0.8);
    padding: 4px 10px;
    border-radius: 0;
}

.social-post-box .date-box svg{
    font-size: 14px;
}

.info-hover-chart{
    transition: all 0.3s;
    box-shadow: 0;
}

.info-hover-chart:hover{
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}


.post-holder-card{
    width: 100%;
    border-radius: 0;
    padding: 30px 30px;
    background-color: #fff;
    box-shadow: rgba(0,0,0,0.2), 0px, 4px, 10px -5px;
}

.post-settings-smallbtn{
    border-radius: 0;
    background-color: #eee7ff;
    border: 0;
    color: #925FFF;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.3s;
}

.post-settings-smallbtn:hover{
    opacity: 0.9;
    transform: scale(1.1);
}

.post-delete-btn{
    background-color: #FFE7E7;
    color:#e85D7e
}

.post-delete-btn svg{
    font-size: 16px;
    color: #e85D7e !important;
}

.post-settings-smallbtn svg{
    font-size: 16px;
    color: #925fff;
}

.approval-tag{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    height: 22px;
    font-size: 12px;
    font-weight: 500;
    padding: 12px;
}

.success-tag{
    background-color: #eaffe0;
    color: #36A900 !important;
    border: 1px solid #89e85d;
}

.reject-tag{
    background-color: #ffebf0;
    color: #e85d7e !important;
    border: 1px solid #e85D7e;
}

.comment-tag{
    background-color: #e7edff;
    color: #5d84e8 !important;
    border: 1px solid #5d84e8;
}

.noneed-tag{
    background-color: #f5f5f5;
    color: #1e1e47;
    border: 1px solid #1e1e47;
}

.rate-good{
    color: #36A900 !important;
}

.rate-improvement{
    color: #FF7D05 !important;
}

.rate-bad{
    color: #e85D7e !important;
}

.card-post-social{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 0;
    padding: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #d9d9d9;
    height: 100%;
    justify-content: space-between;
    cursor: pointer;
    transition: border 0.3s;
}


.card-post-social .social-parent-photo{
    border-radius: 50%;
    object-fit: cover;
    width: 40px;
    height: 40px;
}

.card-post-social .span-btn{
    cursor: pointer;
    transition: all 0.3s;
    color: #925FFF !important;
}

.card-post-social:hover {
    border: 1px solid #925FFF;
}

.card-post-social .span-btn:hover{
    color: #1e1e47 !important;
}

.card-post-social .img-holder{
    position: relative;
    width: 100%; 
    max-height: 300px; 
    padding-top: 100%; 
    overflow: hidden; 
}

.card-post-social .img-holder img {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    object-fit: cover;
}

.custom_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 88%;
    max-width: 1000px;
    padding: 20px;
    background-color: white;
    border-radius: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .custom_popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
  }
  
.pricing-box{
    border-radius: 0px;
    padding: 20px;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

.purple-box{
    background-color: #925FFF;
    color: #fff;
}

.white-box{
    background-color: #fff;
    color: #1E1E47;
}

.white-box button {
    padding: 12px;
    width: 100%;
    color: #fff;
    background-color: #925FFF;
    border: 0;
    border-radius: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.purple-box button{
    padding: 12px;
    width: 100%;
    color: #1E1E47;
    background-color: #fff;
    border: 0;
    border-radius: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.pricing-features-purple{
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}

.pricing-features-white{
    font-weight: 400;
    font-size: 14px;
    color: #1E1E47;
}

.pricing-features-white svg{
    font-size: 18px;
    color: #925fff;
}

.pricing-features-purple svg{
    font-size: 18px;
    color: #fff;
}

.invoice-list{
    list-style: none;
    width: 100%;
    list-style-position: unset;
    text-indent: 0;
    padding: 0;
}

.invoice-list li{
    background-color: #fff;
    padding: 10px;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.invoice-list button{
    border: 0;
    color: #925FFF;
    cursor: pointer;
    padding: 4px 10px;
    border-radius: 0px;
    background-color: #efebf8;
    font-weight: 500;
    font-size: 14px;
}

.invoice-list button:hover{
    opacity: 0.9;
}